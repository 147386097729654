<template>
    <div class="news">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>

        <div class="news-content" id="middleArea">
            <el-form :inline="true" class="form-search" style="padding: 30px;">
                <el-form-item label="教师名称">
                    <el-input v-model="teachername" placeholder="请输入教师名称"></el-input>
                </el-form-item>
                <el-form-item label="学校名称">
                    <el-input v-model="schoolname" placeholder="请输入学校名称"></el-input>
                </el-form-item>
                <el-form-item label="开始日期">
                    <el-date-picker v-model="starttime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束日期">
                    <el-date-picker v-model="endtime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="GetTeacherPageList()" :loading="listLoading">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="DownTeacherExport()" :loading="downLoading">导出</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="pagelist" stripe style="width: 100%" @sort-change="sortChange" size="medium"
                :header-cell-style="{ fontSize: '16px' }">
                <el-table-column prop="teacherName" label="教师名称" align="center">
                </el-table-column>
                <el-table-column prop="phone" label="手机号" align="center">
                </el-table-column>
                <el-table-column prop="schoolName" label="学校名称">
                </el-table-column>
                <el-table-column prop="courseDuration" label="时长(小时)" sortable="custom" align="center">
                </el-table-column>
                <el-table-column prop="strStartTime" label="开始日期" sortable="custom" align="center">
                </el-table-column>
                <el-table-column prop="strEndTime" label="结束日期" sortable="custom" align="center">
                </el-table-column>
            </el-table>
            <div class="flex-acjc">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="currentPage" :page-size=this.pagesize layout="prev, pager, next, jumper"
                    :total=this.total>
                </el-pagination>
            </div>
        </div>

        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>
import {
    Message,
    MessageBox
} from 'element-ui' //导入element-ui组件库
import {
    TeacherPageList,
    TeacherExport
} from '@/api/dataanalysis'
import {
    getInfo
} from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'
import {
    getToken
} from '@/utils/cookies.js'

export default {
    name: 'DataDetail',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            listLoading: false,
            downLoading: false,
            currentPage: 1,//初始页
            pagesize: 20,//每页的数据
            users: {},//用户信息
            total: 0,//总数
            teachername: '',
            schoolname: '',
            starttime: '',
            endtime: '',
            listQuery: {
                pageIndex: this.currentPage,
                pageSize: this.pagesize,
                schoolname: this.schoolname,
                teachername: this.teachername,
                starttime: this.starttime,
                endtime: this.endtime,
                sortModelOrder: '',
                sortModelField: ''
            },
            pagelist: [],
            mdd: 0,
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //学校时长分页查询
        GetTeacherPageList()
    },
    methods: {
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //学校时长分页查询
        GetTeacherPageList() {
            var token = getToken();
            if (token) {
                this.listLoading = true
                this.listQuery.pageIndex = this.currentPage;
                this.listQuery.pageSize = this.pagesize;
                this.listQuery.teachername = this.teachername;
                this.listQuery.schoolname = this.schoolname;
                this.listQuery.starttime = this.starttime;
                this.listQuery.endtime = this.endtime;
                TeacherPageList(this.listQuery).then(response => {
                    if (response.status == 200) {
                        this.total = response.data.total;
                        this.pagelist = response.data.list
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 500)
                })
            } else {
                MessageBox.alert("必须登录才可以查看！", "提示", {
                    confirmButtonText: '确定',
                    type: 'info'
                })
            }
        },
        //导出
        DownTeacherExport() {
            var token = getToken();
            if (token) {
                this.downLoading = true
                this.listQuery.schoolname = this.schoolname;
                this.listQuery.starttime = this.starttime;
                this.listQuery.endtime = this.endtime;
                TeacherExport(this.listQuery).then(response => {
                    let download_url = window.URL.createObjectURL(response); //数据流作为下载的源
                    let eleLink = document.createElement('a');  //创建一个a标签
                    eleLink.href = download_url.replace(/^http/, 'https');//下载的文件连接，实际就是下载的数据流 
                    eleLink.download = "教师时长";//下载的文件名称
                    document.body.appendChild(eleLink);
                    eleLink.click()//模拟下载文件
                    window.URL.revokeObjectURL(download_url);
                    document.body.removeChild(eleLink);
                    window.URL.revokeObjectURL(download_url);
                    setTimeout(() => {
                        this.downLoading = false
                    }, 500)
                })
            } else {
                MessageBox.alert("必须登录才可以查看！", "提示", {
                    confirmButtonText: '确定',
                    type: 'info'
                })
            }
        },
        //初始页currentPage、初始每页数据数pagesize和数据data
        handleSizeChange(size) {
            this.pagesize = size;
            this.GetTeacherPageList();
        },
        //点击第几页
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.GetTeacherPageList();
        },
        //排序
        sortChange(column) {
            //判断排序规则
            if (column.order) {
                if (column.order === 'descending') {
                    this.listQuery.sortModelOrder = 'desc'
                } else if (column.order === 'ascending') {
                    this.listQuery.sortModelOrder = 'asc'
                }
                this.listQuery.sortModelField = column.prop
            } else {

                this.listQuery.sortModelOrder = ''
                this.listQuery.sortModelField = ''
            }
            //调用查询list接口
            this.GetTeacherPageList();
        }
    },
    created() {
        Promise.all([this.userInfo()]).then(() => {
            this.GetTeacherPageList();
        })
    },
    mounted() {
        this.$refs.nav.mdd = 8;
        localStorage.setItem("mdd", 8);
        this.mdd = 8;
        const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    },
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;
    }

}
</style>
<style lang="less">
// 手机
@media(max-width:768px) {
    .news {
        .news-content {
            .teaNews {
                flex-direction: column;

                .newsLeft,
                .newsRight {
                    width: 100% !important;
                }
            }

            .teaCourse .box .row {
                width: 49% !important;
            }
        }
    }
}

//平板端
@media(min-width:768px) {
    .news {
        .news-content {
            .teaNews {
                flex-direction: column;

                .newsLeft,
                .newsRight {
                    width: 100% !important;
                }
            }

            .teaCourse .box .row {
                width: 49% !important;
            }
        }
    }
}

//小屏
@media(min-width:992px) {
    .news {
        .news-content {
            .teaNews {
                flex-direction: row;

                .newsLeft,
                .newsRight {
                    //width: 49% !important;
                    width: 100% !important;
                }
            }

            .teaCourse .box .row {
                width: 25% !important;

                .courseImg {
                    height: 120px !important;
                }
            }

        }
    }
}

//大屏
@media(min-width:1300px) {
    .news {
        .news-content {
            .teaCourse .box .row {
                .courseImg {
                    height: 150px !important;
                }
            }
        }
    }
}

@media(min-width:1600px) {
    .news {
        .news-content {
            .teaCourse .box .row {
                .courseImg {
                    height: 180px !important;
                }
            }
        }
    }
}
</style>